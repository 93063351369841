.layout {
    display: grid;
    grid-template-columns: 100%;
    gap: var(--space-lg);
}


.sliderButton {
    background-color: var(--primary);
    width: 3.6rem;
    height: 3.6rem;
    border-radius: var(--default-button-radius);
}
.sliderButton svg {
    width: 1.4rem;
    height: 1.4rem;
}
.prev {
    left: -1.8rem;
}
.next {
    right: -1.8rem;
}

@media(max-width:599px){
    .layout aside {
        width: calc(100vw - 4rem);
    }
    .layout main {
        width: calc(100vw - 4rem);
    }

    .sliderButton {
        width: 3rem;
        height: 3rem;
    }
    .sliderButton svg {
        width: 1rem;
        height: 1rem;
    }
}


@media(min-width:1200px) {
    .layout {
        grid-template-columns: auto 65%;
    }
    
}




