.gridLayout {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background-color: var(--secondary);
}

/* image */

.imageContainer img {
    display: block;
}

.spacer {
    position: relative;
}


.iconBox {
    width: 5rem;
    height: 5rem;
    border: 1px solid currentColor;
    border-radius: var(--default-radius);

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.icon {
    fill: white;
    width: 60%;
}

.montageGraphicTop,
.montageGraphicBottom {
    display: none;
}

.box.bottom {
    display: flex;
    align-items: flex-end;
}



@media (max-width:599px) {
    .spacer {
        display: none;
    }
    .imageContainer {
        margin: var(--space-xs) var(--space-md);
    }
    .imageContainer:not(:last-child) {
        margin-bottom: 0;
    }
}


@media (min-width:600px) {
    .gridLayout {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .double,
    img.double {
        grid-column: span 2;
    }
    .triple,
    img.triple {
        grid-column: span 3;
    }
    
}
@media (min-width:800px) {
    .gridLayout {
        position: relative;
        display: grid;
        grid-template-columns: 20% 20% 30% 30%;
    }

    .montageGraphicTop {
        display: block;
        grid-column: span 2;
        position: relative;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .montageGraphicBottom {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
    }

}