@media(max-width:799px){
    .graphic {
        overflow: hidden;
        margin-top: -5rem;
    }
    
    .graphicBase {
        overflow: hidden;
        margin-bottom: -5px;
    }
}

@media(min-width:800px){
    
    .layout {
        display: grid;
        grid-template-columns: var(--layout-split);        
    }
    
    .quote {
        /* padding: var(--space-lg); */
    }
    
    .graphic {
        background-color: var(--secondary);
        overflow: hidden;
        display: flex;
        align-items: flex-end;
    }

    .graphicWrapper {
        aspect-ratio: 720/630;
        width: 70%;
    }
    
    .graphicBase {
        overflow: hidden;
        display: flex;
        align-items: flex-end;
    }

    .graphicBaseWrapper {
        aspect-ratio: 720/500;
        width: 70%;
    }

    .tab {
        height: var(--space-lg);
    }

}