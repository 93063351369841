@media(max-width:799px){
    .layout {
        padding: var(--space-lg);
    }
    .content {
        margin-bottom: var(--space-lg);
    }
}

@media(max-width:900px){
    .cube, .graphic {
        display: none;
    }
    
}

@media(min-width:800px){
    
    .layout {
        position: relative;
        display: grid;
        grid-template-columns: var(--layout-split);
        /* gap: var(--space-lg); */
    
        padding: var(--space-lg);
    }
    
    .content {
        text-align: right;
        padding-right: var(--space-md);
    }
    
    .cube {
        position: absolute;
        right: 0;
        bottom: 0;
        aspect-ratio: 350/380;
        width: 20rem;
    }
    
    .topTab {
        height: var(--space-md);
        margin-left: 40%;
    }
    
    .bottomTab {
        position: absolute;
        top: 0;
        height: var(--space-md);
        width: 40%;
    }
    
    
    .graphicLayout {
        position: relative;
        margin-bottom: -3rem;
        /* background-color: rgba(0, 255, 255, 0.264); */
    }
    .graphic {
        width: 50%;
        /* background-color: rgba(0, 255, 0, 0.326); */
        display: flex;
        justify-content: flex-end;
        
    }
    .graphicWrapper {
        position: relative;
        z-index: -1;
        width: calc(90% - var(--space-lg));
        aspect-ratio: 800/500;
        /* background-color: red; */
    }

}