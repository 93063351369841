.layout {
    background-color: var(--accent);
}
.slider {
    padding: 0 var(--space-lg);
    
}


@media(max-width:799px){
    
    .layout {
        padding: var(--space-lg);
    }
    .slider {
        margin: var(--space-md) 0;
    }
    .body {
        margin-top: var(--space-md);
    }

}

@media(min-width:800px){
    
    .layout {
        display: grid;
        grid-template-columns: var(--layout-split);
        grid-template-rows: auto var(--space-md) auto auto;
    }
    
    .heading {
        grid-area: 1/1 / 3/2;
        padding: var(--space-lg) var(--space-lg) calc(var(--space-lg) * 1.5) var(--space-lg);
        display: flex;
        align-items: flex-end;
        background-color: white;
        text-align: right;
    }
    
    .body {
        grid-area: 1/2 / 2/3;
        padding: var(--space-lg);
        display: flex;
        align-items: flex-end;
    }
    
    .slider {
        grid-area: 2/1 / 4/3;
        padding: 0 var(--space-lg);
    }
    
    .actions {
        grid-area: 4/1 / 5/2;
        padding: var(--space-lg);
    }

}