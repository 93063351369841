.layout {
    padding: var(--space-lg);
}
.heading {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm)
}
.content {
    margin-top: var(--space-md);
}


@media(min-width:900px) {
    .heading {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: var(--space-md)
    }
    .content {
        padding-left: 15vw;
    }
}