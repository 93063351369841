.gridLayout {
    padding: var(--space-md);
    display: grid;
    gap: var(--space-md);
    grid-template-columns: repeat(1, 1fr);

    max-width: var(--container-max-width);
    margin: 0 auto;
}

/* image */

.imageContainer img {
    border-radius: var(--default-radius);
}


/* quote */

.quoteContainer {
    /* padding: var(--space-sm); */
}

.iconBox {
    width: 5rem;
    height: 5rem;
    border: 1px solid currentColor;
    border-radius: var(--default-radius);
    padding: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.icon {
    /* fill: white; */
    width: 60%;
}

@media (max-width:599px) {
    .spacer {
        display: none;
    }
}


@media (min-width:600px) {
    .gridLayout {
        padding: var(--space-md) var(--space-md);
        display: grid;
        gap: var(--space-sm);
        grid-template-columns: repeat(2, 1fr);
    }
    .double,
    img.double {
        grid-column: span 2;
    }
}
@media (min-width:1200px) {
    .gridLayout {
        padding: var(--space-md) var(--space-sm);
        display: grid;
        gap: var(--space-sm);
        grid-template-columns: repeat(4, 1fr);
    }
    
}