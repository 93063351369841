@media(max-width:800px){
    .container {
        display: none;   
    }
}

.container {
    overflow: hidden;
    max-width: 18rem;
}

.spinner {
    /* border: 1px dashed lime; */
    transform-origin: center;
    aspect-ratio: 1;

    padding: 1rem;

    animation: spin 12s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg);}
    100% { transform: rotate(-360deg);}
}