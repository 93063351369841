.layout {
    overflow: hidden;
}

.scrollPaneContent {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--space-sm);
}

@media(max-width:799px){
    .heading {
        margin: var(--space-lg);
    }
    .scrollPane {
        margin: 0 var(--space-lg);
    }
    .scrollPaneNav {
        display: none;
    }
}


@media(min-width:800px){

    
    .topTab {
        height: var(--space-md);
        width: 40%;
    }
    .bottomTab {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        height: var(--space-md);
        width: 40%;
        background-color: white;
    }
    
    .layout {
        position: relative;
        z-index: 2;
        padding: 0 var(--space-lg);

        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: var(--space-lg);
        row-gap: var(--space-md);
    }

    .heading {
        grid-area: 1/1 / 2/2;
        justify-self: flex-end;
        padding-top: var(--space-lg);
        text-align: right;
    }
    
    .scrollPane {
        position: absolute;
        top: 0;
        bottom: 0;
        grid-area: 1/2 / 3/3;
        margin-right: var(--space-lg);
        /* overflow: hidden; */
        
        
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--space-sm);

        border-right: 1px solid var(--primary);


    }
    
    .scrollPaneContent {
        --scrollbarBackground: var(--secondary);
        --scrollbarBackgroundBorder: 1px solid var(--secondary);
        --thumbBackground: var(--primary, black);
        --thumbBorder: 6px solid var(--secondary);
        --borderRadius: 0px;

        padding: var(--space-lg) var(--space-lg) var(--space-lg) 0;
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: var(--thumbBackground) var(--scrollbarBackground);
        
    }
    .scrollPaneContent::-webkit-scrollbar {
        border-radius: var(--borderRadius);
        border: var(--scrollbarBackgroundBorder);
        width: 24px;
    }
    .scrollPaneContent::-webkit-scrollbar-track {
        background: var(--scrollbarBackground);
        border-radius: var(--borderRadius);
    }
    .scrollPaneContent::-webkit-scrollbar-thumb {
        background-color: var(--thumbBackground);
        border-radius: var(--borderRadius);
        border: var(--thumbBorder);
    }
    
    .scrollPaneNav {
        padding-top: var(--space-lg);
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        align-self: flex-start;
    }
    
    .graphic {
        grid-area: 2/1 / 3/2;
        display: flex;
        justify-content: flex-end;
    }
    .graphicWrapper {
        aspect-ratio: 800/630;
        width: 90%;
    }

}